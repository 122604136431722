@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&family=Unbounded:wght@500&display=swap");

* {
  font-family: "Space Grotesk", sans-serif !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

::-webkit-scrollbar {
  background-color: #4488ff44;
  width: 5px;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #4488ff;
  border-radius: 5px;
}

video {
  object-fit: contain !important;
}
